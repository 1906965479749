<template>
  <v-container
    id="subscriptions"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="yellow darken-1"
          icon="mdi-format-list-bulleted"
        >
          <template #after-heading>
            <div class="display-2 font-weight-light">
              {{ $tc('overview', 1) }}
            </div>
          </template>

          <v-data-table
            :items="subscriptions.data"
            :headers="subscriptions.headers"
            :footer-props="subscriptions.footer"
            :items-per-page="subscriptions.options.itemsPerPage"
            :loading="subscriptions.loading"
            :search.sync="subscriptions.search"
          >
            <template #top>
              <v-btn
                class="float-right ml-2"
                dark
                small
                color="primary"
                @click="getSubscriptions()"
              >
                <v-icon dark>
                  mdi-cached
                </v-icon>
              </v-btn>
              <v-text-field
                v-model="subscriptions.search"
                append-icon="mdi-magnify"
                :label="$tc('search', 1)"
                hide-details
                single-line
                style="max-width: 250px;"
              />
              <v-divider class="mt-5" />
            </template>

            <template #[`item.status.name`]="{ item }">
              <div v-if="item.variation_id === 19">
                <v-chip
                  color="success"
                  small
                >
                  {{ $tc("statuses.trial", 1) }}
                </v-chip>
              </div>
              <div v-else>
                <v-chip
                  :color="getStatusColor(item.status.code)"
                  small
                >
                  {{ $tc("statuses." + item.status.name, 1) }}
                </v-chip>
              </div>
            </template>

            <template #[`item.license_start_date`]="{ item }">
              <div v-if="item.variation_id === 19">
                {{ $moment(item.trial_start_date).format('DD-MM-YYYY') }}
              </div>
              <div v-else>
                {{ $moment(item.license_start_date).format('DD-MM-YYYY') }}
              </div>
            </template>

            <template #[`item.license_end_date`]="{ item }">
              <div v-if="item.variation_id === 19">
                {{ $moment(item.trial_end_date).format('DD-MM-YYYY') }}
              </div>
              <div v-else>
                <span v-if="item.license_end_date">{{ $moment(item.license_end_date).format('DD-MM-YYYY') }}</span>
                <span v-else>-</span>
              </div>
            </template>

            <template #[`item.interval.id`]="{ item }">
              {{ $tc(getIntervalName(item.interval.id), 1) }}
            </template>

            <template #[`item.services[0].pivot.price_invoice`]="{ item }">
              &euro;{{ item.services[0].pivot.price_invoice }}
            </template>

            <template #[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editSubscription(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                small
                class="mr-2"
                @click="deleteSubscription(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Subscriptions',

    data: function () {
      return {
        store: this.$store,
        subscriptions: {
          data: [],
          headers: [
            {
              text: this.$tc('id', 1),
              value: 'object.id',
            },
            {
              text: this.$tc('channel', 1),
              value: 'services[0].mv_name',
            },
            {
              text: this.$tc('status', 1),
              value: 'status.name',
            },
            {
              text: this.$tc('start', 1),
              value: 'license_start_date',
            },
            {
              text: this.$tc('end', 1),
              value: 'license_end_date',
            },
            {
              text: this.$tc('interval', 1),
              value: 'interval.id',
            },
            {
              text: this.$tc('price', 1),
              value: 'services[0].pivot.price_invoice',
            },
            /* {
              text: this.$tc('action', 2),
              value: 'actions',
              align: 'end',
            }, */
          ],
          footer: {
            itemsPerPageAllText: '-',
            itemsPerPageOptions: [5, 10, 25, -1],
            itemsPerPageText: '',
            pageText: '{0}-{1} / {2}',
          },
          options: {
            itemsPerPage: 25,
          },
          loading: true,
          search: '',
        },
      }
    },
    computed: {
    },
    watch: {
    },
    mounted () {
      this.getSubscriptions()
    },
    methods: {
      getSubscriptions () {
        var self = this
        self.subscriptions.loading = true
        self.subscriptions.data = []
        self.$http.get('/api/subscription?include=services,interval,status,object').then(function (response) {
          self.subscriptions.data = response.data
          self.subscriptions.loading = false
        }, function (response) {
          self.subscriptions.loading = false
        })
      },
      getStatusColor (code) {
        if (code === 100) {
          return 'success'
        } else {
          return 'info'
        }
      },
      getIntervalName (id) {
        if (id === 1) {
          return '4weekly'
        } else if (id === 4) {
          return 'quaterly'
        } else if (id === 7) {
          return 'yearly'
        } else if (id === 9) {
          return 'monthly'
        } else {
          return ''
        }
      },
    },
  }
</script>
