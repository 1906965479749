var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"subscriptions","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{attrs:{"color":"yellow darken-1","icon":"mdi-format-list-bulleted"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$tc('overview', 1))+" ")])]},proxy:true}])},[_c('v-data-table',{attrs:{"items":_vm.subscriptions.data,"headers":_vm.subscriptions.headers,"footer-props":_vm.subscriptions.footer,"items-per-page":_vm.subscriptions.options.itemsPerPage,"loading":_vm.subscriptions.loading,"search":_vm.subscriptions.search},on:{"update:search":function($event){return _vm.$set(_vm.subscriptions, "search", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn',{staticClass:"float-right ml-2",attrs:{"dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.getSubscriptions()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cached ")])],1),_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$tc('search', 1),"hide-details":"","single-line":""},model:{value:(_vm.subscriptions.search),callback:function ($$v) {_vm.$set(_vm.subscriptions, "search", $$v)},expression:"subscriptions.search"}}),_c('v-divider',{staticClass:"mt-5"})]},proxy:true},{key:"item.status.name",fn:function(ref){
var item = ref.item;
return [(item.variation_id === 19)?_c('div',[_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v(" "+_vm._s(_vm.$tc("statuses.trial", 1))+" ")])],1):_c('div',[_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status.code),"small":""}},[_vm._v(" "+_vm._s(_vm.$tc("statuses." + item.status.name, 1))+" ")])],1)]}},{key:"item.license_start_date",fn:function(ref){
var item = ref.item;
return [(item.variation_id === 19)?_c('div',[_vm._v(" "+_vm._s(_vm.$moment(item.trial_start_date).format('DD-MM-YYYY'))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$moment(item.license_start_date).format('DD-MM-YYYY'))+" ")])]}},{key:"item.license_end_date",fn:function(ref){
var item = ref.item;
return [(item.variation_id === 19)?_c('div',[_vm._v(" "+_vm._s(_vm.$moment(item.trial_end_date).format('DD-MM-YYYY'))+" ")]):_c('div',[(item.license_end_date)?_c('span',[_vm._v(_vm._s(_vm.$moment(item.license_end_date).format('DD-MM-YYYY')))]):_c('span',[_vm._v("-")])])]}},{key:"item.interval.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc(_vm.getIntervalName(item.interval.id), 1))+" ")]}},{key:"item.services[0].pivot.price_invoice",fn:function(ref){
var item = ref.item;
return [_vm._v(" €"+_vm._s(item.services[0].pivot.price_invoice)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editSubscription(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteSubscription(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }